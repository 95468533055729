import React from "react";

const ProgressBar = (props) => {
  //   const [isVisible, setIsVisible] = useState(0);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  return (
    <div className="progress_wrapper">
      <div className="progress_wrapper__therm">
        <div
          className="progress_wrapper__therm__inner"
          style={{
            width: `${props.progress + 0.5}%`,
          }}
        ></div>
      </div>
      <div className="text_wrap">
        <div className="progress_wrapper__raised">
          {formatter.format(props.raised).replace(".00", "")} Raised
          <span>*</span>
        </div>
        <div className="progress_wrapper__goal">
          Goal: {formatter.format(props.goal).replace(".00", "")}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
