import React from "react";
import ReactDOM from "react-dom";
import * as tiltify from "tiltify-lib";
import App from "./App";
// import * as serviceWorker from './serviceWorker';

import "./scss/index.scss";

import ReactGA from "react-ga";
ReactGA.initialize("G-ZXRNQ0L375");
ReactGA.pageview(window.location.pathname + window.location.search);

tiltify.UseProxy("https://12v5i9usl3.execute-api.us-east-1.amazonaws.com/api/");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
