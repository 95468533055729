import React from "react";

const Hero = (props) => {
  return <section className="hero">
    <section className="hero__content">
      {props.children}
    </section>
    {props.caption && <span className="caption">{props.caption}</span>}
  </section>
}

export default Hero;