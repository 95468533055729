import React, { useState, useEffect, useRef } from "react";

import * as tiltify from "tiltify-lib";
import { Header, Footer, Hero, ProgressBar } from "../layout";
import * as images from "../../images/";
// import video from "../../files/doritos.mp4";

const Doritos = () => {
  const [raised, setRaised] = useState(0);
  const [goal, setGoal] = useState(0);
  const [percent, setPercent] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isVisible, setVisible] = React.useState(false);

  const domRef = useRef();

  useEffect(() => {
    //console.log("TEAM_CAMPAIGN", process.env.REACT_APP_TEAM_CAMPAIGN);
    async function GetData() {
      tiltify.SetPublicMode();
      const c = await tiltify.GetCampaign(
        process.env.REACT_APP_IND_CAMPAIGN,
        false
      );
      // console.log(c);
      setRaised(Number(c.total_amount_raised.value));
      // setRaised(40000);
      setGoal(Number(c.goal.value.replace(/\.00$/, "")));
    }
    GetData();
  }, []);

  useEffect(() => {
    setPercent(Number(100 * (Number(raised) / Number(goal))));
    setProgress(percent > 100 ? 100 : percent);
  }, [goal, percent, raised]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((element) => {
        if (element.isIntersecting === true) {
          setVisible(true);
          observer.unobserve(domRef.current);
        }
      });
    });
    observer.observe(domRef.current);
  }, []);

  return (
    <div className="doritos">
      <div className="overlay_lp__top">
        <Header type="normal" link="/" />
        <Hero>
          <div className="hero__content__box">
            <img
              className="logos"
              src={images.logos}
              alt="Doritos Polaris Logos"
            />
            <h1>going zero gravity for a cause</h1>
            <p>
              Doritos® and St.&nbsp;Jude Children's Research Hospital® have
              teamed up with Polaris Dawn for an out-of-this-world mission. Join
              us by donating today!
            </p>
            <ProgressBar raised={raised} goal={goal} progress={progress} />
            <div className="donate_buttons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://donate.tiltify.com/738da34f-4c5b-4e9e-9bf3-3aaa6d4bee58/details"
              >
                <span className="cta-primary">One-Time Donation</span>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://donate.tiltify.com/738da34f-4c5b-4e9e-9bf3-3aaa6d4bee58/incentives"
              >
                <span className="cta-primary">Donate To Win Prizes</span>
              </a>
            </div>
            <img
              className="icons"
              src={images.doritosIcons}
              alt="Doritos icons"
            />
            <div className="video_wrapper">
              {/* <img src={images.doritosVideo} alt="placement" /> */}
              <video width="800" controls loop autoPlay muted>
                <source
                  src="https://doritos.experience.stjude.org/doritos.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="disclaimer">
              *FRITO-LAY HAS MADE AN INITIAL DONATION OF $500,000 TO ST. JUDE.
            </div>
            {/* <a
              target="_blank"
              rel="noreferrer"
              href="https://donate.tiltify.com/e76cf8ec-854b-49d4-b840-d87f246d51c1/details"
            >
              <span className="cta-primary">Donate</span>
            </a> */}
            {/* <button
              className="hero-home__cta"
              onClick={() => {
                scroll(fundraisingRef);
              }}
            >
              <span className="cta-secondary">Register to Fundraise</span>
            </button> */}
          </div>
        </Hero>
      </div>
      <div className="steps_wrapper">
        <section className="inner-row gutters constraing overlay_lp__content">
          <div className="col-6">
            <h1>A Mission with a Mission</h1>
            <p>
              Doritos® champions boldness everywhere: stepping outside comfort
              zones, pushing the limits, and paving new paths—here on Earth and
              up in space. That's why the boldest chip is taking on the boldest
              challenge.
            </p>

            <p>
              Doritos® has teamed up with Polaris Dawn and St. Jude Children's
              Research Hospital® to raise funds and fuel awareness for childhood
              cancer research and treatment on Earth by sending a chip to space.
            </p>

            <p>
              TRY ANOTHER ANGLE™ and join us by donating to help raise funds and
              awareness for St. Jude's lifesaving mission: Finding cures. Saving
              children®. As a thank you for your donation, you'll receive a
              canister of the limited-edition DORITOS® MINIS COOL RANCH® ZERO
              GRAVITY FLAVORED TORTILLA CHIPS that the astronauts enjoyed in
              space! While supplies last.
            </p>
          </div>
          <div className="col-6 plaque">
            <img src={images.doritosPlaque} alt="Doritos Plaque" />
          </div>
        </section>
      </div>
      <div className="grid_wrapper">
        <section className="inner-row gutters constraing overlay_lp__content">
          <div className={`col-7 canister`}>
            <img src={images.LE} alt="Limited Edition" className="LE" />
            <img
              src={images.doritosCanister2}
              alt="Doritos Canister"
              className={`canister-image  ${isVisible ? "is-visible" : ""}`}
            />
          </div>
          {/* <div className="col-1"></div> */}
          <div className="col-5">
            <h1>
              One small chip for man,
              <br />
              one giant leap for Doritos®
            </h1>
            <p>
              Doritos® is making cHIpSTORY by sending our chips to space. We
              created a space-approved seasoning to keep the Cool Ranch® flavor
              from floating away, allowing the astronauts to enjoy their
              favorite chips in zero gravity.{" "}
            </p>

            <p ref={domRef}>
              Instead of the classic Doritos® dust, an innovative oil-based
              coating keeps the zesty flavor you know and love on the chips. The
              chips are also made in minis, designed to be eaten in one
              delicious bite. Together, these space-friendly modifications
              reduce any snacking debris that could affect the space module.{" "}
            </p>

            <p>
              While packaged in space-approved tins for flight, down here on
              Earth OUR LIMITED-EDITION DORITOS® COME IN OUR SIGNATURE MINIS
              CANISTER, ENHANCED WITH GLOW-IN-THE-DARK FEATURES TO COMMEMORATE
              OUR VOYAGE TO THE STARS.
            </p>

            <p>
              Join the mission and donate today to try this astronaut-approved
              chip for yourself! While supplies last.
            </p>
          </div>
        </section>
      </div>
      <div className="donation_wrapper">
        <section
          className="inner-row gutters constraing overlay_lp__content prizes"
          // ref={rewardsRef}
        >
          <div className="col-12">
            <div className="col-12">
              <h2>Houston, we have prizes</h2>
            </div>
            <p>
              Experience out-of-this-world flavor: donate for a chance to try
              our limited-edition DORITOS® MINIS COOL RANCH® ZERO GRAVITY
              FLAVORED TORTILLA CHIPS, win mission-inspired swag, and more!
            </p>
          </div>
          <div className="col-4 prizes_wrapper">
            <div className="prizes__amount">$50 Donation</div>
            <img
              src={images.bundle}
              className="item"
              alt="DORITOS® MISSION KIT"
            />
            <p className="title">
              <strong>DORITOS® MISSION KIT</strong>
            </p>
            {/* <p>This will be replaced with incentives copy</p> */}
          </div>
          <div className="col-4 prizes_wrapper">
            <div className="prizes__amount">$50 Donation</div>
            <img
              src={images.shirt}
              className="item"
              alt="DORITOS® MISSION T-SHIRT"
            />
            <p className="title">
              <strong>DORITOS® MISSION T-SHIRT</strong>
            </p>
            {/* <p>This will be replaced with incentives copy</p> */}
          </div>
          <div className="col-4 prizes_wrapper">
            <div className="prizes__amount">$150 Donation</div>
            <img
              src={images.jacket}
              className="item"
              alt="DORITOS® MISSION JACKET WITH PATCH"
            />
            <p className="title">
              <strong>DORITOS® MISSION JACKET WITH PATCH</strong>
            </p>
            {/* <p>This will be replaced with incentives copy</p> */}
          </div>
          <div className="col-4 prizes_wrapper">
            <div className="prizes__amount">$200 Donation</div>
            <img
              src={images.LOT}
              className="item"
              alt=" DORITOS® MINIS COOL RANCH® ZERO GRAVITY FLAVORED TORTILLA CHIPS"
            />
            <p className="title">
              <strong>
                DORITOS® MINIS COOL RANCH® ZERO GRAVITY FLAVORED TORTILLA CHIPS
              </strong>
            </p>
            {/* <p>This will be replaced with incentives copy</p> */}
          </div>
          <div className="col-4 prizes_wrapper">
            <div className="prizes__amount">$250 Donation</div>
            <img
              src={images.backpack}
              className="item"
              alt="DORTIOS® MISSION BACKPACK WITH PATCH"
            />
            <p className="title">
              <strong>DORTIOS® MISSION BACKPACK WITH PATCH</strong>
            </p>
            {/* <p>This will be replaced with incentives copy</p> */}
          </div>
          <div className="col-4 prizes_wrapper">
            <div className="prizes__amount">$300 Donation</div>
            <img
              src={images.tinSticker}
              className="item"
              alt="SIGNED REPLICA MISSION TINS"
            />
            <p className="title">
              <strong>SIGNED REPLICA MISSION TINS</strong>
            </p>
            {/* <p>This will be replaced with incentives copy</p> */}
          </div>
          <div className="col-4 prizes_wrapper">
            <div className="prizes__amount">$1,000 Donation</div>
            <img
              src={images.tinEtched}
              className="item"
              alt="DORITOS® TINS FROM SPACE"
            />
            <p className="title">
              <strong>DORITOS® TINS FROM SPACE</strong>
            </p>
          </div>
          <div className="col-4 prizes_wrapper">
            <div className="prizes__amount">$2,000 Donation</div>
            <img src={images.VMA} className="item" alt="TWO VMA TICKETS" />
            <p className="title">
              <strong>TWO VMA TICKETS</strong>
            </p>
          </div>
          <div className="col-4 prizes_wrapper">
            <div className="prizes__amount">$10,000 Donation</div>
            <img
              src={images.celebration}
              className="item"
              alt="TWO CELEBRATION EVENT TICKETS"
            />
            <p className="title">
              <strong>TWO CELEBRATION EVENT TICKETS</strong>
            </p>
          </div>
          <div className="col-12">
            <a
              href="https://donate.tiltify.com/738da34f-4c5b-4e9e-9bf3-3aaa6d4bee58/incentives"
              rel="noreferrer"
              target="_blank"
            >
              <span className="cta-primary">Donate to win prizes</span>
            </a>
          </div>
          {/* <div className="col-3 prizes_wrapper">
            <div className="prizes__amount">$100+ Donation</div>
            <img
              src={images.doritosPrize}
              className="item"
              alt="Relay FM Sticker Bundle"
            />
            <p className="title">
              <strong>Incetives Title</strong>
            </p>
            <p>This will be replaced with incentives copy</p>
          </div>
          <div className="col-3 prizes_wrapper">
            <div className="prizes__amount">$60+ Donation</div>
            <img
              src={images.doritosPrize}
              className="item"
              alt="Relay FM Wallpapers and MacOS Screensaver"
            />
            <p className="title">
              <strong>Incetives Title</strong>
            </p>
            <p>This will be replaced with incentives copy</p>
          </div>
          <div className="col-3 prizes_wrapper">
            <div className="prizes__amount">$100+ Donation</div>
            <img
              src={images.doritosPrize}
              className="item"
              alt="Relay FM Sticker Bundle"
            />
            <p className="title">
              <strong>incentives Title</strong>
            </p>
            <p>This will be replaced with incentives copy</p>
          </div> */}
        </section>
      </div>
      <div className="social_wrapper">
        <h1>get social</h1>
        <p>Follow us for more stellar content.</p>
        <div className="social_wrapper_icons">
          <a href="https://www.tiktok.com/@doritos?lang=en">
            <img src={images.tiktok} alt="tiktok" />
          </a>
          <a href="https://www.instagram.com/doritos/?hl=en">
            <img src={images.instagram} alt="instagram" />
          </a>
          <a href="https://twitter.com/Doritos">
            <img src={images.x} alt="x" />
          </a>
          <a href="https://www.facebook.com/DoritosUSA/">
            <img src={images.facebook} alt="facebook" />
          </a>
          {/* <a href="https://www.youtube.com/user/crashthesuperbowl/crashthesuperbowl">
            <img src={images.youtube} alt="youtube" />
          </a> */}
          <a href="https://www.threads.net/@doritos?hl=en">
            <img src={images.threads} alt="threads" />
          </a>
        </div>
      </div>
      <div className="doritos_footer">
        <img src={images.doritosLogoWhite} alt="Doritos logo" />
      </div>

      <Footer />
    </div>
  );
};

export default Doritos;
