import React from "react";

import doritosLogo from "../../images/doritos_logo2.png";

const Header = (props) => {
  return (
    <header>
      <section className="banner red">
        <section className="logo">
          {props.type === "normal" ? (
            <div>
              <a href={props.link}>
                <img src={doritosLogo} alt="Doritos: Try Another Angle" />
              </a>
            </div>
          ) : (
            <div>
              <a href="/" alt="St. Jude" className="sjlogo">
                <span>St. Jude</span>
              </a>

              <a href="/" alt="Play Live" className="program-name">
                <span>
                  <strong>St. Jude</strong>
                  <br />
                  Play Live<span className="logo-subsite-trademark">®</span>
                </span>
              </a>
            </div>
          )}
        </section>
      </section>
    </header>
  );
};

export default Header;
